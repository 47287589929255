import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  baseUrl,
  noOfGullaList,
  rotliTypeList,
  batchSize,
  heightSelectionList
} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";

function FinishedGoods() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [filteredList,setFilteredList] = useState([])
  const [consumptionDetail, setConsumptionDetail] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [methodType, setMethodType] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [batchIndex, setBatchIndex] = useState(0);
  const [vesselList, setVesselList] = useState([]);
  const [trollyList, setTrollyList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [method, setMethod] = useState("")
  const [formData, setFormData] = useState([]);
  const [rotliProduction, setRotliProduction] = useState([]);
  const [rotliDetail, setRotliDetail] = useState({});
  const [totalActualRMQty, setTotalActualRMQty] = useState(0);
  const [prodPlanningData,setProdPlanningData] = useState({})
  const prevFilledHeight = useRef(null);

  useEffect(() => {
    getVesselList();
    getTrollyList();
    getMethodList();
  }, []);
  useEffect(() => {
    // Create initial formData and validationErrors based on mealList length
    const initialFormData = Array.from({ length: batchSize }, (_, index) => ({
      batch: index + 1,
      total_raw_qty: "",
      batch_raw_qty: "",
      remarks_kgs: "",
      remarks_ltr: "",
      remarks_nos: "",
      remarks_readymade_kgs : "",
      remarks_readymade_ltr : "",
      remarks_readymade_nos : "",
      rotli_type: {id: 1, label: "Machine"},
      fg_qty: '',
      current_raw_to_fg_multiplier: "",
      std_raw_atta_to_finished_atta_multiplier: "",
      gulla_weight: '',
      gulla_count: "",
      avg_finished_roti_weight: "",
      weight_of_1_filled_bowl : "",
      no_of_roti_from_1_gulla: {id: 1, label: "1"},
      no_of_roti_bhakhri: "",
      total_no_of_roti_bhakhri: "",
      production_vessel: "",
      actual_production_vessel: "",
      height_selection : "",
      height : "",
      sensor_volume: "",
      manual_volume: "",
      batch_volume: "",
      total_manual_volume: "",
      trolly_name: "",
      actual_vessel_name: "",
      gross_weight_fg: '',
      weight_of_empty_trolly: '',
      weight_of_empty_vessel: '',
      net_weight: "",
      total_net_weight: "",
    }));
    setFormData(initialFormData);
  }, [batchSize]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getProdSupportDetailForItem();
    }
  }, [mealList, activeIndex]);

  useEffect(() => {
    if(date && session && methodList.length > 0){
      handelMealList()
    }
    
  }, [date,session, methodList]);

  useEffect(()=>{
    console.log('method',method)
    console.log(mealList)
    let filterlist;
        // console.log(method);
    if(method && method.length > 0){
        const methodIds = method.map(method => method.value);
        filterlist = mealList.filter((item) => methodIds.includes(item?.measurements?.id));
    } else{
      filterlist = mealList;
    }  
    console.log(filterlist)
    setFilteredList(filterlist);
  },[method,mealList])

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      const itemId =
        mealList.find((item, index) => index === activeIndex).menu_item_id ??
        null;
      console.log(itemId);
      const fetchDetail = async () => {
        const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${itemId}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=production_support&condition=${condition}`
        );

        const finishedGoods = await axios.get(
          `${baseUrl}/comman/list?table=finished_goods&condition=${condition} AND method = '${methodType}'`
        );

        const rotliProduction = await axios.get(
          `${baseUrl}/comman/list?table=rotli_production&condition=${condition}`
        );

        const productionPlanning = await axios.get(
          `${baseUrl}/comman/list?table=production_planning&condition=${condition}`
        )
        console.log('production plannnin data',productionPlanning.data.data[0])
        setProdPlanningData(productionPlanning.data.data[0])
        setRotliProduction(rotliProduction.data?.data ?? []);
        const supportDetail = response.data.data;
        const finishedGoodsDetail = finishedGoods.data.data;
        const total = supportDetail.reduce((total, obj) => {
          // console.log(obj);
            const qty = parseFloat(obj.batch_raw_material_qty);
            return isNaN(qty) ? total : total + qty;
          }, 0).toFixed(0) ?? 0;
          console.log(total)
          setTotalActualRMQty(total);
        getDetailDispaly(supportDetail, finishedGoodsDetail,consumptionDetail, total);
      };
      fetchDetail();
    }
  }, [mealList, activeIndex, methodType, consumptionDetail]);

  useEffect(() => {
    console.log(rotliProduction);
    if(rotliProduction.length > 0){
      
      const total_kacho_lot = rotliProduction.reduce((total, obj) => {
                                const qty = parseFloat(obj.weight_of_kacho_lot);
                                return isNaN(qty) ? total : total + qty;
                              }, 0) ?? 0;
      const total_mon_lot = rotliProduction.reduce((total, obj) => {
                              const qty = parseFloat(obj.weight_of_mon_lot);
                              return isNaN(qty) ? total : total + qty;
                            }, 0) ?? 0;
      const current_raw_multiplier = total_kacho_lot > 0 ? (total_mon_lot / total_kacho_lot).toFixed(2) : 0;

      const total_gulla_weight =  rotliProduction.reduce((total, obj) => {
                                    const qty = parseFloat(obj.gulla_weight);
                                    return isNaN(qty) ? total : total + qty;
                                  }, 0) ?? 0;

      const gulla_weight = total_gulla_weight / rotliProduction.length ?? 0;

      const total_gulla_count = rotliProduction.reduce((total, obj) => {
                                  const qty = parseFloat(obj.no_of_gulla);
                                  return isNaN(qty) ? total : total + qty;
                                }, 0).toFixed(0) ?? 0;
                                console.log(total_gulla_count, "total_gulla_count");
      
      const no_of_roti = (1 * total_gulla_count).toFixed(0) ?? 0;

      const total_roti = no_of_roti;

      const rotliDetail = {
        "total_batch" :rotliProduction.length,
        "total_kacho_lot" :total_kacho_lot,
        "total_mon_lot" :total_mon_lot,
        "current_raw_multiplier" : current_raw_multiplier,
        "std_atta" : consumptionDetail[0]?.raw_atta_to_finished_atta_multiplier ?? 0,
        "gulla_weight": gulla_weight.toFixed(0),
        'total_gulla_count': total_gulla_count,
        "no_of_roti": no_of_roti,
        'total_roti': total_roti
      };
      setRotliDetail(rotliDetail);
    }
  }, [rotliProduction]);

  const getDetailDispaly = async (supportDetail, finishedGoodsDetail,consumptionDetail, totalActualRMQty) => {
    try {
      if (supportDetail) {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            total_raw_qty: "",
            batch_raw_qty: totalActualRMQty,
            remarks_kgs: "",
            remarks_ltr: "",
            remarks_nos: "",
            remarks_readymade_kgs : "",
            remarks_readymade_ltr : "",
            remarks_readymade_nos : "",
            rotli_type: {id: 1, label: "Machine"},
            fg_qty: '',
            current_raw_to_fg_multiplier: "",
            std_raw_atta_to_finished_atta_multiplier: "",
            gulla_weight: '',
            gulla_count: "",
            avg_finished_roti_weight : '',
            weight_of_1_filled_bowl: "",
            no_of_roti_from_1_gulla: {id: 1, label: "1"},
            no_of_roti_bhakhri: "",
            total_no_of_roti_bhakhri: '',
            production_vessel: "",
            actual_production_vessel: "",
            height_selection : "",
            height : "",
            sensor_volume: "",
            manual_volume: "",
            batch_volume: "",
            total_manual_volume: "",
            trolly_name: "",
            actual_vessel_name: "",
            gross_weight_fg: '',
            weight_of_empty_trolly: '',
            weight_of_empty_vessel: '',
            net_weight: "",
            total_net_weight : ""
          })
        );
        const updatedFormData = initialFormData.map((initialItem) => {
          const supportObject = supportDetail.find(
            (object) => object.batch == initialItem.batch
          );
          const finishedGoodsObject = finishedGoodsDetail.find(
            (object) => object.batch == initialItem.batch
          );
          
          if(methodType === 3){
            const std_atta_multiplier =
            consumptionDetail?.[0]?.raw_atta_to_finished_atta_multiplier ?? 0;
                    // console.log(std_atta_multiplier, "std_atta_multiplier")
                    // console.log(initialItem.batch_raw_qty);
                    const fg_qty = (initialItem.batch_raw_qty * std_atta_multiplier).toFixed(3) ?? 0;
                   initialItem.fg_qty = fg_qty ?? 0;
                   initialItem.std_raw_atta_to_finished_atta_multiplier = std_atta_multiplier;
          }
          if (supportObject && !finishedGoodsObject) {
            initialItem.batch_raw_qty = supportObject.batch_raw_material_qty;

            if (methodType == 2) {
              const prodVesselId = supportObject.vessel_id;
              const prodVesselItem = vesselList.find(
                (item) => item.id == prodVesselId
              );
              const prodVesselObject = prodVesselItem
                ? { value: prodVesselItem.id, label: prodVesselItem.name }
                : { value: "", label: "" };

              initialItem.production_vessel = prodVesselObject;
            }

            if (finishedGoodsObject) {
              initialItem.batch_raw_qty = finishedGoodsObject.batch_raw_qty;
              if (methodType == 1) {
                const trollyItem = trollyList.find(
                  (item) => item.id == finishedGoodsObject.trolly_name
                );
                const trollyObject = trollyItem
                  ? { value: trollyItem.id, label: trollyItem.name }
                  : { value: "", label: "" };
                const actualVesselItem = vesselList.find(
                  (item) => item.id == finishedGoodsObject.actual_vessel_name
                );
                const actualVesselObject = actualVesselItem
                  ? { value: actualVesselItem.id, label: actualVesselItem.name }
                  : { value: "", label: "" };

                initialItem.trolly_name = trollyObject;
                initialItem.actual_vessel_name = actualVesselObject;
                initialItem.gross_weight_fg =
                  finishedGoodsObject.gross_weight_fg;
                initialItem.weight_of_empty_trolly =
                  finishedGoodsObject.weight_of_empty_trolly;
                initialItem.weight_of_empty_vessel =
                  finishedGoodsObject.weight_of_empty_vessel;
                initialItem.net_weight = finishedGoodsObject.net_weight;
                initialItem.total_net_weight = finishedGoodsObject.total_net_weight;
                initialItem.remarks_kgs = finishedGoodsObject.remarks_kgs;
              }

              if (methodType == 2) {
                const actualProductionVesselItem = vesselList.find(
                  (item) =>
                    item.id == finishedGoodsObject.actual_production_vessel
                );
                const actualProductionVesselObject = actualProductionVesselItem
                  ? {
                      value: actualProductionVesselItem.id,
                      label: actualProductionVesselItem.name,
                    }
                  : { value: "", label: "" };
                const heightItem = heightSelectionList.find(
                    (item) => item.value == finishedGoodsObject.height_selection
                );
                const hegithObject = heightItem
                    ? { value: heightItem.value, label: heightItem.label }
                    : { value: "", label: "" };
                initialItem.actual_production_vessel =
                  actualProductionVesselObject;
                initialItem.height_selection = hegithObject;
                initialItem.height = finishedGoodsObject.height;

                initialItem.sensor_volume = finishedGoodsObject.sensor_volume;
                initialItem.manual_volume = finishedGoodsObject.manual_volume;
                initialItem.batch_volume = finishedGoodsObject.batch_volume;
                initialItem.total_manual_volume = finishedGoodsObject.total_manual_volume;
                initialItem.remarks_ltr = finishedGoodsObject.remarks_ltr;
              }

              if (methodType == 3) {
                const rotliTypeItem = rotliTypeList.find(
                  (item) => item.id == finishedGoodsObject.rotli_type
                );
                const rotliTypeObject = rotliTypeItem
                  ? { value: rotliTypeItem.id, label: rotliTypeItem.label }
                  : { value: "", label: "" };

                const rotiFromGullaItem = noOfGullaList.find(
                  (item) =>
                    item.id == finishedGoodsObject.no_of_roti_from_1_gulla
                );
                const rotiFromGullaObject = rotiFromGullaItem
                  ? {
                      value: rotiFromGullaItem.id,
                      label: rotiFromGullaItem.label,
                    }
                  : { value: "", label: "" };

                initialItem.rotli_type = rotliTypeObject;
                initialItem.fg_qty = finishedGoodsObject.fg_qty;
                initialItem.current_raw_to_fg_multiplier =
                  finishedGoodsObject.current_raw_to_fg_multiplier;
                initialItem.std_raw_atta_to_finished_atta_multiplier =
                  finishedGoodsObject.std_raw_atta_to_finished_atta_multiplier;
                initialItem.gulla_weight = finishedGoodsObject.gulla_weight;
                initialItem.gulla_count = finishedGoodsObject.gulla_count.toFixed(0);
                initialItem.avg_finished_roti_weight = finishedGoodsObject.avg_finished_roti_weight;
                initialItem.weight_of_1_filled_bowl = finishedGoodsObject.weight_of_1_filled_bowl;
                initialItem.no_of_roti_from_1_gulla = rotiFromGullaObject;
                initialItem.no_of_roti_bhakhri = finishedGoodsObject.no_of_roti_bhakhri;
                initialItem.total_no_of_roti_bhakhri = finishedGoodsObject.total_no_of_roti_bhakhri;
                initialItem.remarks_nos = finishedGoodsObject.remarks_nos;
              }
              if(methodType == 4){
                initialItem.remarks_readymade_kgs = finishedGoodsObject.remarks_readymade_kgs;
                
              }
              if(methodType == 5){
                initialItem.remarks_readymade_ltr = finishedGoodsObject.remarks_readymade_ltr;
              }

              if(methodType == 6){
                initialItem.remarks_readymade_nos = finishedGoodsObject.remarks_readymade_nos;
              }
            }
            // console.log(initialItem, "initialItem");
            return { ...initialItem };
          } else if (finishedGoodsObject) {
            if (finishedGoodsObject) {
              initialItem.batch_raw_qty = finishedGoodsObject.batch_raw_qty;
              if (methodType == 1) {
                const trollyItem = trollyList.find(
                  (item) => item.id == finishedGoodsObject.trolly_name
                );
                const trollyObject = trollyItem
                  ? { value: trollyItem.id, label: trollyItem.name }
                  : { value: "", label: "" };
                const actualVesselItem = vesselList.find(
                  (item) => item.id == finishedGoodsObject.actual_vessel_name
                );
                const actualVesselObject = actualVesselItem
                  ? { value: actualVesselItem.id, label: actualVesselItem.name }
                  : { value: "", label: "" };

                initialItem.trolly_name = trollyObject;
                initialItem.actual_vessel_name = actualVesselObject;
                initialItem.gross_weight_fg =
                  finishedGoodsObject.gross_weight_fg;
                initialItem.weight_of_empty_trolly =
                  finishedGoodsObject.weight_of_empty_trolly;
                initialItem.weight_of_empty_vessel =
                  finishedGoodsObject.weight_of_empty_vessel;
                initialItem.net_weight = finishedGoodsObject.net_weight;
                initialItem.total_net_weight = finishedGoodsObject.total_net_weight;
                initialItem.remarks_kgs = finishedGoodsObject.remarks_kgs;
                // initialItem.filled_height = finishedGoodsObject.filled_height;
              }

              if (methodType == 2) {
                const actualProductionVesselItem = vesselList.find(
                  (item) =>
                    item.id == finishedGoodsObject.actual_production_vessel
                );
                const actualProductionVesselObject = actualProductionVesselItem
                  ? {
                      value: actualProductionVesselItem.id,
                      label: actualProductionVesselItem.name,
                    }
                  : { value: "", label: "" };
                
                const heightItem = heightSelectionList.find(
                    (item) => item.value == finishedGoodsObject.height_selection
                );
                const hegithObject = heightItem
                    ? { value: heightItem.value, label: heightItem.label }
                    : { value: "", label: "" };
                initialItem.actual_production_vessel =
                  actualProductionVesselObject;
                initialItem.height_selection = hegithObject;
                initialItem.height = finishedGoodsObject.height;
                initialItem.sensor_volume = finishedGoodsObject.sensor_volume;
                initialItem.manual_volume = finishedGoodsObject.manual_volume;
                initialItem.batch_volume = finishedGoodsObject.batch_volume;
                initialItem.total_manual_volume = finishedGoodsObject.total_manual_volume;
                initialItem.remarks_ltr = finishedGoodsObject.remarks_ltr;
              }

              if (methodType == 3) {
                const rotliTypeItem = rotliTypeList.find(
                  (item) => item.id == finishedGoodsObject.rotli_type
                );
                const rotliTypeObject = rotliTypeItem
                  ? { value: rotliTypeItem.id, label: rotliTypeItem.label }
                  : { value: "", label: "" };

                const rotiFromGullaItem = noOfGullaList.find(
                  (item) =>
                    item.id == finishedGoodsObject.no_of_roti_from_1_gulla
                );
                const rotiFromGullaObject = rotiFromGullaItem
                  ? {
                      value: rotiFromGullaItem.id,
                      label: rotiFromGullaItem.label,
                    }
                  : { value: "", label: "" };

                initialItem.rotli_type = rotliTypeObject;
                initialItem.fg_qty = finishedGoodsObject?.fg_qty;
                initialItem.current_raw_to_fg_multiplier =
                  finishedGoodsObject.current_raw_to_fg_multiplier;
                initialItem.std_raw_atta_to_finished_atta_multiplier =
                  finishedGoodsObject.std_raw_atta_to_finished_atta_multiplier;
                initialItem.gulla_weight = finishedGoodsObject.gulla_weight;
                initialItem.gulla_count = finishedGoodsObject.gulla_count;
                initialItem.avg_finished_roti_weight = finishedGoodsObject.avg_finished_roti_weight;
                initialItem.weight_of_1_filled_bowl = finishedGoodsObject.weight_of_1_filled_bowl;
                initialItem.no_of_roti_from_1_gulla = rotiFromGullaObject;
                initialItem.no_of_roti_bhakhri = finishedGoodsObject.no_of_roti_bhakhri;
                initialItem.total_no_of_roti_bhakhri = finishedGoodsObject.total_no_of_roti_bhakhri;
                initialItem.remarks_nos = finishedGoodsObject.remarks_nos;
              }

              if(methodType == 4){
                initialItem.remarks_readymade_kgs = finishedGoodsObject.remarks_readymade_kgs;
                
              }
              if(methodType == 5){
                initialItem.remarks_readymade_ltr = finishedGoodsObject.remarks_readymade_ltr;
              }

              if(methodType == 6){
                initialItem.remarks_readymade_nos = finishedGoodsObject.remarks_readymade_nos;
              }
            }

            // console.log(initialItem, "initialItem");
            return { ...initialItem };
          } else {
            return { ...initialItem };
          }
        });
        console.log(updatedFormData);
        setFormData(updatedFormData);
      } else {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            total_raw_qty: "",
            batch_raw_qty: "",
            remarks_kgs: "",
            remarks_ltr: "",
            remarks_nos: "",
            remarks_readymade_kgs : "",
      remarks_readymade_ltr : "",
      remarks_readymade_nos : "",
            rotli_type: {id: 1, label: "Machine"},
            fg_qty: '',
            current_raw_to_fg_multiplier: "",
            std_raw_atta_to_finished_atta_multiplier: "",
            gulla_weight: '',
            gulla_count: "",
            avg_finished_roti_weight : "",
            weight_of_1_filled_bowl: "",
            no_of_roti_from_1_gulla: {id: 1, label: "1"},
            no_of_roti_bhakhri: "",
            total_no_of_roti_bhakhri: "",
            production_vessel: "",
            actual_production_vessel: "",
            height_selection : "",
            height : "",
            sensor_volume: "",
            manual_volume: "",
            batch_volume: "",
            total_manual_volume: "",
            trolly_name: "",
            actual_vessel_name: "",
            gross_weight_fg: '',
            weight_of_empty_trolly: '',
            weight_of_empty_vessel: '',
            net_weight: "",
            total_net_weight: ""
          })
        );
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (methodType == 1 && formData[batchIndex]) {
      const trollyWeight = isNaN(parseFloat(
        formData[batchIndex].weight_of_empty_trolly
      )) ? 0 : parseFloat(
        formData[batchIndex].weight_of_empty_trolly
      );
      const vesselWeight = isNaN(parseFloat(
        formData[batchIndex].weight_of_empty_vessel
      )) ? 0 : parseFloat(
        formData[batchIndex].weight_of_empty_vessel
      );
      const grossFG = parseFloat(formData[batchIndex].gross_weight_fg);

      const net_weight = isNaN(grossFG - (vesselWeight + trollyWeight))
        ? 0
        : (grossFG - (vesselWeight + trollyWeight)).toFixed(2);
      console.log(net_weight);
      if (formData[batchIndex].net_weight !== net_weight) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            net_weight: net_weight,
          };
          return updatedFormData;
        });
      }
      const total_weight = formData.reduce((accumulator, currentValue) => {
        let type;
        if (typeof currentValue.net_weight === "string") {
          const floatValue = parseFloat(currentValue.net_weight);
          if (!isNaN(floatValue)) {
            type = floatValue; // If valid, assign the float value
          } else {
            type = 0; // If not valid, assign 0 or handle it according to your requirement
          }
        } else {
          type = currentValue.net_weight;
        }
        return accumulator + type;
      }, 0);
      
      // const count = formData.filter(obj => obj.net_weight).length;
      
      const total_net_weight = (total_weight ).toFixed(2);
      if (formData[batchIndex].total_net_weight !== total_net_weight) {
        setFormData((prevFormData) => {
          const updatedFormData = prevFormData.map(item => ({
              ...item,
              total_net_weight: total_net_weight,
          }));
          return updatedFormData;
        });
      }
    }

    if (methodType == 3 && formData[batchIndex]) {
      const fg_qty = formData[batchIndex].fg_qty;
      const raw_qty = formData[batchIndex].batch_raw_qty;
      const current_raw_to_fg_multiplier =
        raw_qty > 0 ? (fg_qty / raw_qty).toFixed(2) : 0;

      console.log(consumptionDetail);
      const std_atta_multiplier =
        consumptionDetail?.[0]?.raw_atta_to_finished_atta_multiplier ?? 0;

      if (
        formData[batchIndex].std_raw_atta_to_finished_atta_multiplier !==
        std_atta_multiplier
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            std_raw_atta_to_finished_atta_multiplier: std_atta_multiplier,
          };
          return updatedFormData;
        });
      }

      if (
        formData[batchIndex].current_raw_to_fg_multiplier !==
        current_raw_to_fg_multiplier
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            current_raw_to_fg_multiplier: current_raw_to_fg_multiplier,
          };
          return updatedFormData;
        });
      }

      const gulla_weight = formData[batchIndex].gulla_weight;
      const gulla_count = gulla_weight > 0 ? ((fg_qty / gulla_weight) * 1000).toFixed(0) : 0;
      if (formData[batchIndex].gulla_count !== gulla_count) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            gulla_count: gulla_count,
          };
          return updatedFormData;
        });
      }

      if (formData[batchIndex].no_of_roti_bhakhri !== gulla_count) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            no_of_roti_bhakhri: gulla_count,
          };
          return updatedFormData;
        });
      }

      const total_nos_of_roti_bhakhri = formData.reduce((accumulator, currentValue) => {
          let type;
          if (typeof currentValue.no_of_roti_bhakhri === "string") {
            const floatValue = parseFloat(currentValue.no_of_roti_bhakhri);
            if (!isNaN(floatValue)) {
              type = floatValue; // If valid, assign the float value
            } else {
              type = 0; // If not valid, assign 0 or handle it according to your requirement
            }
          } else {
            type = currentValue.no_of_roti_bhakhri;
          }
          return accumulator + type;
        }, 0);
        
        // const count = formData.filter(obj => obj.no_of_roti_bhakhri).length;
        
        const total_no_of_roti_bhakhri = (total_nos_of_roti_bhakhri ).toFixed(3);
        if (formData[batchIndex].total_no_of_roti_bhakhri !== total_no_of_roti_bhakhri) {
          setFormData((prevFormData) => {
            const updatedFormData = prevFormData.map(item => ({
                ...item,
                total_no_of_roti_bhakhri: total_no_of_roti_bhakhri,
            }));
            return updatedFormData;
          });
        }
    }
  }, [formData, methodType, batchIndex]);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getVesselList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=vessel_master&condition=status=1`
    );
    setVesselList(response.data.data);
  };

  const getTrollyList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=trolly_master&condition=status=1`
    );
    setTrollyList(response.data.data);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const getProdSupportDetailForItem = async () => {
    const item_id = mealList.find((item, index) => index == activeIndex);

    const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${item_id.menu_item_id}'`;
    const response = await axios.get(
      `${baseUrl}/comman/list?table=production_support&condition=${condition}`
    );
    const sum = response.data.data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.batch_raw_material_qty;
    }, 0);
    console.log(item_id.measurements.id ?? item_id.method);
    setMethodType(item_id.measurements.id);
    if (item_id?.measurements?.id == 3) {
      const cpCondition = `menu_item_id = '${item_id.menu_item_id}' AND method = '${item_id.measurements.id}'`;
      const consumptionDetail = await axios.get(
        `${baseUrl}/comman/list?table=consumption_master&condition=${cpCondition}`
      );
      setConsumptionDetail(consumptionDetail.data.data);
    }
  };

  const handelFilterChange = async (date, session,method) => {
    setDate(date);
    setSession(session);
    setMethod(method)
    if (date && session) {
      // const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      // const response = await axios.get(
      //   `${baseUrl}/meal_master/item_list?condition=${condition}`
      // );
      // const newData = response.data.data.reduce((acc, obj) => {
      //   const measurementsArray = obj?.measurements?.split(",") ?? [obj.method];
      //   measurementsArray.forEach((measurement) => {
      //     acc.push({
      //       ...obj,
      //       measurements: methodList.find((v) => v.id == measurement) ?? {},
      //     });
      //   });
      //   return acc;
      // }, []);
      // console.log(methodList);
      // console.log(newData);
      // setMealList(newData);
    }
  };

  const handelMealList = async () => {
    const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      console.log('mealData',response.data.data)
      const newData = response.data.data.reduce((acc, obj) => {
        const measurementsArray = obj?.measurements?.split(",") ?? [obj.method];
        measurementsArray.forEach((measurement) => {
          acc.push({
            ...obj,
            measurements: methodList.find((v) => v.id == measurement) ?? {},
          });
        });
        return acc;
      }, []);
      console.log(methodList);
      console.log(newData);
      setMealList(newData);
  }

  const handleMealDataInputChange = (index) => (e) => {
    const {name,value} = e.target;
      setFilteredList((prevList) => {
        const updatedList = [...prevList];
        updatedList[index] = {...updatedList[index],[name]:value}
        return updatedList
      })
  }

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    if(methodType == 2){
      
      if(name == "sensor_volume"){
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          const manual_volume = updatedFormData[index]["manual_volume"] ?? 0;
          const batch_volume = value > 0 ? value : manual_volume;
          updatedFormData[index] = { ...updatedFormData[index], "batch_volume": batch_volume, [name] : value };
          return updatedFormData;
        });
      }else{
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = { ...updatedFormData[index], [name]: value };
          return updatedFormData;
        });
      }
     
      
    }else if(methodType == 3){
      if(name == "batch_raw_qty"){
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          const std_atta_multiplier =
        consumptionDetail?.[0]?.raw_atta_to_finished_atta_multiplier ?? 0;
        const fg_qty = (value * std_atta_multiplier).toFixed(2);
          updatedFormData[index] = { ...updatedFormData[index], [name]: value, ["fg_qty"]: fg_qty };
          return updatedFormData;
        });
      }else{
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = { ...updatedFormData[index], [name]: value };
          return updatedFormData;
        });
      }
    }else{
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        return updatedFormData;
      });
    }
    
    setBatchIndex(index);
  };

  const handleBlur = (index) => (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData((prevFormData) => {
         
          const updatedFormData = [...prevFormData];
          const vessel = vesselList.find((item) => item.id == updatedFormData[index].actual_production_vessel.value);
          const total_height = vessel?.total_height ?? 0;
          const enter_height = updatedFormData[index]["height"] === "" || updatedFormData[index]["height"] == null ? 0 : updatedFormData[index]["height"];
          const height = updatedFormData[index]["height_selection"].value == 1 ? enter_height : (total_height - enter_height);
         
          const vessel_rad = vessel?.top_dia / 2 ?? 0;
          const volume = ((3.14 * vessel_rad * vessel_rad * height) / 1000).toFixed(2);
         
          updatedFormData[index] = { ...updatedFormData[index], "height" : updatedFormData[index]["height"], height_selection : updatedFormData[index]["height_selection"], manual_volume : volume };
          const total_volume = updatedFormData.reduce((accumulator, currentValue) => {
            const manualVolume = parseFloat(currentValue.manual_volume) || 0;
            return accumulator + manualVolume;
          }, 0);
        
          const total_manual_volume = total_volume.toFixed(2);
        
          return updatedFormData.map(item => ({
            ...item,
            total_manual_volume: total_manual_volume,
          }));
        });
  }

  const handleSelectChange = async (index, data, option) => {
    setBatchIndex(index);
    if (methodType == 1) {
      if (option.name == "trolly_name") {
        const trollyWeight =
          trollyList.find((item) => item.id == data.value).empty_weight ?? 0;
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            [option.name]: data,
            weight_of_empty_trolly: trollyWeight,
          };
          return updatedFormData;
        });
      }
      if (option.name == "actual_vessel_name") {
        const vesselWeight =
          vesselList.find((item) => item.id == data.value).empty_weight ?? 0;
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            [option.name]: data,
            weight_of_empty_vessel: vesselWeight,
          };
          return updatedFormData;
        });
      }
    }else if(option.name == "actual_production_vessel"){
      const vessel = vesselList.find((item) => item.id == data.value);
      const total_height = vessel?.total_height ?? 0;
      const vessel_rad = vessel?.top_dia / 2 ?? 0;
      
      const volume = ((3.14 * vessel_rad * vessel_rad * total_height) / 1000).toFixed(2);
      
      setFormData((prevFormData) => {
        const updatedFormData = prevFormData.map((item, i) => 
          i === index 
            ? { ...item, [option.name]: data, filled_height: total_height, manual_volume: volume }
            : item
        );
      
        const total_volume = updatedFormData.reduce((accumulator, currentValue) => {
          const manualVolume = parseFloat(currentValue.manual_volume) || 0;
          return accumulator + manualVolume;
        }, 0);
      
        const total_manual_volume = total_volume.toFixed(2);
      
        return updatedFormData.map(item => ({
          ...item,
          total_manual_volume: total_manual_volume,
        }));
      });
      } else {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
        };
        return updatedFormData;
      });
    }
  };

  const handleSave = async (e, index) => {
    e.preventDefault();

    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      methodType: methodType,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/finished_goods/batch`, combineData);
      toast.success("Data successfully saved!");
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
  };

  const handleMealDataSave = async (e, index) => {
    e.preventDefault();
    const combineData = {
      mealData: filteredList[index],
    };
    console.log(filteredList[index]);
    const id = filteredList[index]?.id
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/meal_master/qty_update/${id}`, combineData);
      toast.success("Data successfully saved!");
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
  };

  // const getTotalRawQty = () => {
  //   const sum = formData.reduce((accumulator, currentValue) => {
  //     let type;
  //     if (typeof currentValue.batch_raw_qty === "string") {
  //       const floatValue = parseFloat(currentValue.batch_raw_qty);
  //       if (!isNaN(floatValue)) {
  //         type = floatValue; // If valid, assign the float value
  //       } else {
  //         type = 0; // If not valid, assign 0 or handle it according to your requirement
  //       }
  //     } else {
  //       type = currentValue.batch_raw_qty;
  //     }
  //     return accumulator + type;
  //   }, 0);
  //   return sum.toFixed(2);
  // };

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Finished Goods</h1>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {filteredList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>
                  {`${item.item_name} (${item.eng_name})`} (
                  {item.measurements.name})
                </span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="lg:w-96">
                    {item?.measurements?.id == 3 && <div className="flex flex-wrap justify-between bg-yellow-300">
                              <div className="sm:text-sm lg:text-base font-bold">
                              Total Actual Raw Material Qty. (Kgs.) :
                              </div>
                              <div>
                                {totalActualRMQty ?? 0}
                              </div>
                            </div>}

                            {item?.measurements?.id == 1 && <div className="flex flex-wrap justify-between bg-yellow-300">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Planned Raw Material Qty (Kgs.) :
                      </div>
                      <div>
                        {prodPlanningData.planned_batch_raw_qty ?? 0}
                      </div>
                    </div>}
                    

                    {item?.measurements?.id == 2 && <div className="flex flex-wrap justify-between bg-yellow-300">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Planned Raw Material Qty (Ltrs.) :
                      </div>
                      <div>
                        {prodPlanningData.planned_batch_raw_qty ?? 0}
                      </div>
                    </div>}
                    

                    {item?.measurements?.id == 3 && <div className="flex flex-wrap justify-between bg-yellow-300">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Planned Raw Material Qty (Nos.) :
                      </div>
                      <div>
                        {prodPlanningData.planned_batch_raw_qty ?? 0}
                      </div>
                    </div>}
                  </div>
                  <div className="w-auto flex space-x-5">
                  {item?.measurements?.id == 1 && <div className="w-96 flex flex-wrap justify-between ">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Actual Raw Material Qty (Kgs.) :
                      </div>
                      <div>
                              <input
                                type="number"
                                step="0.01"
                                id="actual_raw_material_qty_kgs"
                                placeholder="Actual Raw Material Qty (Kgs.) :"
                                name="actual_raw_material_qty_kgs"
                                value={item?.actual_raw_material_qty_kgs}
                                onChange={handleMealDataInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-20 border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                      </div>
                    </div>}
                  {item?.measurements?.id == 2 && <div className="w-96 flex flex-wrap justify-between ">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Actual Raw Material Qty (Ltrs.) :
                      </div>
                      <div>
                              <input
                                type="number"
                                step="0.01"
                                id="actual_raw_material_qty_ltr"
                                placeholder="Actual Raw Material Qty (Ltrs.) :"
                                name="actual_raw_material_qty_ltr"
                                value={item?.actual_raw_material_qty_ltr}
                                onChange={handleMealDataInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-20 border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                      </div>
                    </div>}
                    {item?.measurements?.id == 3 && <div className="w-96 flex flex-wrap justify-between ">
                      <div className="sm:text-sm lg:text-base font-bold">
                      Actual Raw Material Qty (Nos.) :
                      </div>
                      <div>
                              <input
                                type="number"
                                step="0.01"
                                id="actual_raw_material_qty_nos"
                                placeholder="Actual Raw Material Qty (Nos.) :"
                                name="actual_raw_material_qty_nos"
                                value={item?.actual_raw_material_qty_nos}
                                onChange={handleMealDataInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-20 border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                      </div>
                    </div>}
                          <button
                            onClick={(e) => {
                              handleMealDataSave(e, index);
                            }}
                            className="px-2 py-1 bg-green-500 text-white rounded-md placeholder:text-sm p-1 mr-2"
                          >
                            Save
                          </button>

                  </div>
                  

                  <div className="flex overflow-x-auto">
                    <div className="flex mt-10 flex-col flex-wrap whitespace-nowrap ">
                    <div className="font-bold mt-9"></div>
                      <div className="font-bold mt-3">Batch</div>
                      {(item?.measurements?.id == 4 || item?.measurements?.id == 5 || item?.measurements?.id == 6) && <div className="font-bold mt-4">Batch Raw Qty.</div>}
                      {(item?.measurements?.id != 3) && <div className="font-bold mt-6">Remarks</div>}

                      {item?.measurements?.id == 1 && (
                        <>
                          <div className="font-bold mt-9">Trolly Name</div>
                          <div className="font-bold mt-8">Actual Vessel Name</div>
                          <div className="font-bold mt-7">Gross weight FG(kgs.)</div>
                          <div className="font-bold mt-7">Weight of Empty Trolley (kgs.)</div>
                          <div className="font-bold mt-6">Weight of Empty Vessel (Kgs.)</div>
                          <div className="font-bold mt-7">Net weight - FG (kgs.)</div>
                          <div className="font-bold mt-7">Total Net weight - FG (kgs.)</div>
                          
                        </>
                      )}
                      {item?.measurements?.id == 2 && (
                        <>
                          <div className="font-bold mt-8">Vessel Name</div>
                          <div className="font-bold mt-8">Height Selection</div>
                          <div className="font-bold mt-8">Height(cms)</div>
                          <div className="font-bold mt-6">Sensor Volume</div>
                          <div className="font-bold mt-6">Manual Volume</div>
                          <div className="font-bold mt-6">Batch Volume</div>
                          <div className="font-bold mt-6">Total Manual Volume</div>
                          
                        </>
                      )}
                      {item?.measurements?.id == 3 && (
                        <>
                          <div className="font-bold mt-8">No of Rotis from 1 gulla*</div>
                          <div className="font-bold mt-7">Rotli Type</div>
                          <div className="font-bold mt-8">Raw Qty* (kgs.)</div>
                          <div className="font-bold mt-8">FG Qty* (kgs.)</div>
                          <div className="font-bold mt-7">Gulla weight (gms.)*</div>
                          <div className="font-bold mt-6">Finished roti weight (gms.)</div>
                          <div className="font-bold mt-6">Weight of 1 filled bowl (icebox/Rotli) Kgs.</div>
                          <div className="font-bold mt-6">Gulla count (nos.)*</div>
                          <div className="font-bold mt-7">No. of Rotis/ Bhakhari</div>
                          <div className="font-bold mt-7">Total No. of Rotis/ Bhakhari</div>
                          <div className="font-bold mt-7">Current Raw to FG Multiplier</div>
                          <div className="font-bold mt-7">Std. Raw to FG Multiplier</div>
                          <div className="font-bold mt-7">Remarks</div>
                        </>
                      )}
                    </div>
                   
                    {item?.measurements?.id == 3 && (
                         <div
                       
                         className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 w-52"
                       >
                        <div className="border p-2 bg-yellow-300">Live Rotli Production</div>
                          <div className="border p-2">Total Batch: {rotliDetail?.total_batch ?? 0}</div>
                          <div className="border p-7"></div>
                          <div className="border p-6"></div>
                          <div className="border p-7"></div>
                          <div className="border p-3">{rotliDetail?.total_kacho_lot ?? 0}</div>
                          <div className="border p-3">{rotliDetail?.total_mon_lot ?? 0}</div>
                          <div className="border p-3">{rotliDetail?.gulla_weight ?? 0}</div>
                          <div className="border p-6"></div>
                          <div className="border p-4">1</div>
                          <div className="border p-3">{rotliDetail?.total_gulla_count ?? 0}</div>
                          <div className="border p-3">{rotliDetail?.no_of_roti ?? 0}</div>
                          <div className="border p-3">{rotliDetail?.total_roti ?? 0}</div>
                          <div className="border p-3">{rotliDetail?.current_raw_multiplier ?? 0}</div>
                          <div className="border p-4">{rotliDetail?.std_atta ?? 0}</div>
                          <div className="border p-6 "></div>
                        </div>
                      )}
                    
                    {Array.from({ length: batchSize }, (_, index) => (
                      <div
                        key={index}
                        className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 w-52"
                      >
                        <div className=" p-5"></div>
                        <div className="border p-2">{index + 1}</div>
                        {(item?.measurements?.id == 4 || item?.measurements?.id == 5 || item?.measurements?.id == 6) && <div className="border p-2">
                          <input
                            type="number"
                            step="0.01"
                            id="batch_raw_qty"
                            placeholder="Batch Raw Qty.
                            "
                            name="batch_raw_qty"
                            value={formData?.[index]?.batch_raw_qty}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full border rounded-md  placeholder:text-sm p-1 bg-orange-50"
                          />
                        </div>} 

                        {item?.measurements?.id == 1 && (
                          <>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_kgs"
                                name="remarks_kgs"
                                placeholder="Remarks"
                                value={formData?.[index]?.remarks_kgs}
                                onChange={handleInputChange(index)}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#FFFAF0', 
                                    fontSize: '16px', 
                                  }),
                                }}
                                name="trolly_name"
                                value={formData?.[index].trolly_name}
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                placeholder="Trolly Name"
                                options={trollyList.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#FFFAF0', 
                                    fontSize: '16px', 
                                  }),
                                }}
                                name="actual_vessel_name"
                                value={formData?.[index].actual_vessel_name}
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                placeholder="Actual Vessel Name"
                                options={vesselList.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="gross_weight_fg"
                                name="gross_weight_fg"
                                placeholder="Gross weight FG(kgs.)
                                "
                                value={formData?.[index]?.gross_weight_fg}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_empty_trolly"
                                name="weight_of_empty_trolly"
                                value={
                                  formData?.[index]?.weight_of_empty_trolly
                                }
                                disabled
                                placeholder="Weight of Empty Trolley (kgs.)"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_empty_vessel"
                                name="weight_of_empty_vessel"
                                value={
                                  formData?.[index]?.weight_of_empty_vessel
                                }
                                disabled
                                placeholder="Weight of Empty Vessel (Kgs.)"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="net_weight"
                                name="net_weight"
                                value={formData?.[index]?.net_weight}
                                disabled
                                placeholder="Net weight - FG (kgs.)"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                            {index == 0 &&<div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_net_weight"
                                name="total_net_weight"
                                value={formData?.[index]?.total_net_weight}
                                disabled
                                placeholder="Total Net weight - FG (kgs.)"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-green-100"
                              />
                            </div>}
                          </>
                        )}
                        {item?.measurements?.id == 2 && (
                          <>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_ltr"
                                name="remarks_ltr"
                                placeholder="Remarks"
                                value={formData?.[index]?.remarks_ltr}
                                onChange={handleInputChange(index)}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#FFFAF0', 
                                    fontSize: '16px', 
                                  }),
                                }}
                                name="actual_production_vessel"
                                value={
                                  formData?.[index].actual_production_vessel
                                }
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                placeholder="Vessel Name"
                                options={vesselList.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                }}
                                name="height_selection"
                                value={formData?.[index].height_selection}
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                placeholder="Height Selection"
                                options={heightSelectionList.map((item) => ({
                                  value: item.value,
                                  label: item.label,
                                }))}
                                onBlur={handleBlur(index)}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="height"
                                name="height"
                                placeholder="Height(cms)"
                                defaultValue={formData?.[index]?.height}
                                onChange={handleInputChange(index)}
                                onBlur={handleBlur(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="sensor_volume"
                                name="sensor_volume"
                                placeholder="Sensor Volume"
                                value={formData?.[index]?.sensor_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50" 
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="manual_volume"
                                placeholder="Manual Volume"
                                name="manual_volume"
                                value={formData?.[index]?.manual_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="batch_volume"
                                name="batch_volume"
                                placeholder="Batch Volume"
                                value={formData?.[index]?.batch_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50" 
                              />
                            </div>
                            {index == 0 &&<div className="border p-2">
                             <input
                                type="number"
                                step="0.01"
                                id="total_manual_volume"
                                placeholder="Total Manual Volume"
                                name="total_manual_volume"
                                value={formData?.[index]?.total_manual_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-green-100"
                              />
                            </div>}
                          </>
                        )}
                        {item?.measurements?.id == 3 && (
                          <>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#FFFAF0', 
                                    fontSize: '16px', 
                                  }),
                                }}
                                name="no_of_roti_from_1_gulla"
                                value={
                                  formData?.[index].no_of_roti_from_1_gulla
                                }
                                placeholder="No Of Roti From 1 Gulla"
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                options={noOfGullaList.map((item) => ({
                                  value: item.id,
                                  label: item.label,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label.includes(
                                    inputValue.toLowerCase()
                                  );
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#FFFAF0', 
                                    fontSize: '16px', 
                                  }),
                                }}
                                name="rotli_type"
                                placeholder="Rotli Type"
                                value={formData?.[index].rotli_type}
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                options={rotliTypeList.map((item) => ({
                                  value: item.id,
                                  label: item.label,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="batch_raw_qty"
                                name="batch_raw_qty"
                                placeholder="Raw Qty* (kgs.)"
                                value={formData?.[index]?.batch_raw_qty}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="fg_qty"
                                name="fg_qty"
                                placeholder="FG Qty* (kgs.)"
                                value={formData?.[index]?.fg_qty}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="gulla_weight"
                                placeholder="Gulla weight (gms.)*"
                                name="gulla_weight"
                                value={formData?.[index]?.gulla_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="avg_finished_roti_weight"
                                placeholder="Finished roti weight (gms.)"
                                
                                name="avg_finished_roti_weight"
                                value={formData?.[index]?.avg_finished_roti_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_filled_bowl"
                                placeholder="Weight of 1 filled bowl (icebox+Rotli) Kgs."
                                
                                name="weight_of_1_filled_bowl"
                                value={formData?.[index]?.weight_of_1_filled_bowl}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="gulla_count"
                                placeholder="Gulla Count (nos.)*"
                                disabled
                                name="gulla_count"
                                value={formData?.[index]?.gulla_count}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_roti_bhakhri"
                                placeholder="No. of Rotis/ Bhakhari"
                                disabled
                                name="no_of_roti_bhakhri"
                                value={formData?.[index]?.no_of_roti_bhakhri}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md p-1 placeholder:text-sm bg-lime-300"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_no_of_roti_bhakhri"
                                placeholder="Total No. of Rotis/ Bhakhari"
                                disabled
                                name="total_no_of_roti_bhakhri"
                                value={formData?.[index]?.total_no_of_roti_bhakhri}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md p-1 placeholder:text-sm bg-green-100"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="current_raw_to_fg_multiplier"
                                placeholder="Current Raw to FG Multiplier"
                                disabled
                                name="current_raw_to_fg_multiplier"
                                value={
                                  formData?.[index]
                                    ?.current_raw_to_fg_multiplier
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                placeholder="Std. Raw Multiplier "
                                id="std_raw_atta_to_finished_atta_multiplier"
                                disabled
                                name="std_raw_atta_to_finished_atta_multiplier"
                                value={
                                  formData?.[index]
                                    ?.std_raw_atta_to_finished_atta_multiplier
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_nos"
                                name="remarks_nos"
                                placeholder="Remarks"
                                value={formData?.[index]?.remarks_nos}
                                onChange={handleInputChange(index)}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                          </>
                        )}
                        {item?.measurements?.id == 4  &&
                        <div className="border p-2">
                        <input
                          type="text"
                          id="remarks_readymade_kgs"
                          name="remarks_readymade_kgs"
                          placeholder="Remarks"
                          value={formData?.[index]?.remarks_readymade_kgs}
                          onChange={handleInputChange(index)}
                          className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                        />
                      </div>}
                      {item?.measurements?.id == 5 &&
                        <div className="border p-2">
                        <input
                          type="text"
                          id="remarks_readymade_ltr"
                          name="remarks_readymade_ltr"
                          placeholder="Remarks"
                          value={formData?.[index]?.remarks_readymade_ltr}
                          onChange={handleInputChange(index)}
                          className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                        />
                      </div>}
                      {item?.measurements?.id == 6 &&
                        <div className="border p-2">
                        <input
                          type="text"
                          id="remarks_readymade_nos"
                          name="remarks_readymade_nos"
                          placeholder="Remarks"
                          value={formData?.[index]?.remarks_readymade_nos}
                          onChange={handleInputChange(index)}
                          className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                        />
                      </div>}

                        <div className="border p-2">
                          <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm p-1 mr-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FinishedGoods;
