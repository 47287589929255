import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  baseUrl,
  timeList
} from "../comman/config";
import Select from "react-select";

import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";
// import * as XLSX from 'xlsx';


function ProductionPlanningList() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [mealSummary, setMealSummary] = useState([]);
  const [mealDetail, setMealDetail] = useState([]);
  const [removeMealDetail, setRemoveMealDetail] = useState([]);
  // const [rmDetailForItem, setRMDetailForItem] = useState([]);
  // const [activeIndex, setActiveIndex] = useState(null);
  const [vesselList, setVesselList] = useState([]);
  // const [ingredientList, setIngredientList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const role = localStorage.getItem('userRole');
  // const [formData, setFormData] = useState([]);
  // const [detail, setDetail] = useState([]);

  useEffect(() => {
    getVesselList();
    // getIngredientList();
  }, []);
  // useEffect(() => {
  //   // Create initial formData and validationErrors based on mealList length
  //   const initialFormData = Array.from({ length: batchSize }, (_, index) => ({
  //     batch: index + 1,
  //     planned_batch_raw_qty: "",
  //     vessel_id: "",
  //     start_time: "",
  //     end_time:"",
  //     requisition_no: "",
  //     remarks: "",
  //     ingredientDetail: []
  //   }));
  //   setFormData(initialFormData);
  // }, [batchSize]);

  useEffect(() => {
    if (mealList.length > 0) {
      getRMDetailForItem();
    }
  }, [mealList]);

  useEffect(() => {
    if (mealList.length > 0) {
      
      const fetchDetail = async () => {
        const mealDetails = [];
        for (const item of mealList) {
        // const data = await Promise.all(mealList.map(async(item) => {
            const condition = `menu_item_id='${item.menu_item_id}' AND date = '${date}' AND session='${session.value}'`;
            const response = await axios.get(`${baseUrl}/comman/list?table=production_planning&condition=${condition}`, { headers: { 'Cache-Control': 'no-cache' } });
            if(response?.data?.data.length > 0){
                const sortedData = response.data.data.sort((a, b) => a.batch - b.batch);
            //   return response.data.data.sort((a, b) => a.batch - b.batch).map((value, idex) => {
                for (const value of sortedData) {
                const vesselId = value.vessel_id;
                const vesselItem = vesselList.find((item) => item.id == vesselId);
                const vesselObject = vesselItem ? { value: vesselItem.id, label: vesselItem.name } : { value: "", label: "" };

                const start_time = value.start_time;
                const timeslotItem = timeList.find((item) => item.value == start_time);
                const timeslotObject = timeslotItem ? { value: timeslotItem.value, label: timeslotItem.label } : { value: "", label: "" };

                const end_time = value.end_time;
                const endTimeItem = timeList.find((item) => item.value == end_time);
                const endTimeSlotObject = endTimeItem ?  { value: endTimeItem.value, label: endTimeItem.label } : { value: "", label: "" };
                const frezzCondition = `menu_item_id='${value.menu_item_id}' AND date = '${date}' AND session='${session.value}' AND batch = '${value.batch}'`;
                const checkFrezz = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
                const itemDisable = checkFrezz.data.data.length > 0 ? true : false;
                mealDetails.push({
                  batch: value.batch,
                  menu_item_id: value.menu_item_id,
                  item_name:item.item_name,
                  eng_name: item.eng_name,
                  planned_batch_raw_qty: value.planned_batch_raw_qty,
                  vessel_id: vesselObject,
                  start_time: timeslotObject,
                  end_time:endTimeSlotObject,
                  itemDisable: role == 1 ? false : itemDisable,
                  removeRow : itemDisable,
                  remarks : value?.remarks ?? ""
                })
              }
            }
            else{
                const frezzCondition = `menu_item_id='${item.menu_item_id}' AND date = '${date}' AND session='${session.value}' AND batch = '1'`;
                const checkFrezz = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
                const itemDisable = checkFrezz.data.data.length > 0 ? true : false;
                mealDetails.push({
                batch: 1,
                menu_item_id: item.menu_item_id,
                item_name:item.item_name,
                eng_name: item.eng_name,
                planned_batch_raw_qty: 0,
                vessel_id: '',
                start_time: '',
                end_time:'',
                itemDisable: role == 1 ? false : itemDisable,
                removeRow : itemDisable,
                remarks : ""
              })
            }
        };
        // const flattenedData = data.flat();
        setMealDetail(mealDetails);
        const summary = [];
        for (const item of mealList) {
            const condition = `date='${date}' AND session='${session.value}' AND menu_item_id='${item.menu_item_id}'`;
            const response = await axios.get(`${baseUrl}/comman/list?table=raw_material&condition=${condition}`, { headers: { 'Cache-Control': 'no-cache' } });
            summary.push(response.data.data[0]);
        }
        
  
      const dataArray = summary.map((item,idx) => ({
          menu_item_id: mealList[idx].menu_item_id,
          item_name:mealList[idx].item_name,
          eng_name: mealList[idx].eng_name,
          total_planned_raw_qty: item?.planned_rm_qty || 0,
          total_planned_batch_raw_qty: 0,
          pending_planned_qty: 0
      }));

        setMealSummary(dataArray);

        updateSummedQuantities(mealDetails, dataArray);
     
      };
      fetchDetail();
    }
  }, [mealList, vesselList]);


  const getVesselList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=vessel_master&condition=status=1`,
      { headers: { 'Cache-Control': 'no-cache' } }
    );
    setVesselList(response.data.data);
  };

  // const getIngredientList = async () => {
  //   const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`, { headers: { 'Cache-Control': 'no-cache' } });
  //   const list = response.data.data.filter((item) => item.status == 1);
  //   setIngredientList(list);
  // };

  const getRMDetailForItem = async () => {
    const data = await Promise.all(mealList.map(async (item) => {
        const condition = `date='${date}' AND session='${session.value}' AND menu_item_id='${item.menu_item_id}'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=raw_material&condition=${condition}`, { headers: { 'Cache-Control': 'no-cache' } });
        return response.data.data[0];
    }));

    const dataArray = data.map((item,idx) => ({
        menu_item_id: mealList[idx].menu_item_id,
        item_name:mealList[idx].item_name,
        eng_name: mealList[idx].eng_name,
        total_planned_raw_qty: item?.planned_rm_qty || 0,
        total_planned_batch_raw_qty: 0,
        pending_planned_qty: 0
    }));
    // console.log(mealList);
    const mealArray = mealList.map(item => ({
        batch: 1,
        menu_item_id: item.menu_item_id,
        item_name:item.item_name,
        eng_name: item.eng_name,
        planned_batch_raw_qty: 0,
        vessel_id: "",
        start_time: "",
        end_time:"",
        remarks: ""
    }));
    // console.log(data);
    setMealSummary(dataArray);
    setMealDetail(mealArray);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`, { headers: { 'Cache-Control': 'no-cache' } }
      );
      setMealList(response.data.data);
    }
  };


  const handleSave = async (e) => {
    e.preventDefault();

    const newMessages = [];

    mealSummary.forEach((item, index) => {
        if (item.pending_planned_qty < 0) {
            newMessages.push(`${item.item_name} માટે નક્કી થયેલ જથ્થા કરતા પ્રોડક્શન જથ્થો "ઓછો" લીધેલ છે.`);
        } else if (item.pending_planned_qty > 0) {
            newMessages.push(`${item.item_name} માટે નક્કી થયેલ જથ્થા કરતા પ્રોડક્શન જથ્થો "વધારે" લીધેલ છે.`);
        }
    });
    const validateData = (data) => {
        const errors = [];
      
        data.forEach((item) => {
          if (item.planned_batch_raw_qty === "" || item.planned_batch_raw_qty == null) {
            errors.push({
              item_name: item.item_name,
              batch: item.batch,
              error: "Planned batch raw quantity is required."
            });
          }
      
          if (item.vessel_id.value === "" || item.vessel_id.value == null) {
            errors.push({
              item_name: item.item_name,
              batch: item.batch,
              error: "Vessel ID is required."
            });
          }
        });
      
        return errors;
      };
    const errors = validateData(mealDetail);
    if(errors.length > 0){
        const allerror = [];
        errors.forEach((error) => {
            allerror.push(`'${error.item_name}' batch ${error.batch}: ${error.error} \n`);
        });
        alert(allerror);
        return;
    }
    if(newMessages.length > 0){
        if (window.confirm(newMessages.join('\n'))) {
            // User clicked OK, proceed to call the API
            callApi();
        }
    }else{
       
        callApi();
    }
  };
async function callApi() {
    const combineData = {
        formData: mealDetail,
        date: date,
        session: session,
        removeMealDetail:removeMealDetail
    };
    try {
        await axios.post(`${baseUrl}/production_planning/all-batch`, combineData);
        toast.success("Data successfully saved!");
    } catch (error) {
        toast.error("Failed to submit the form.");
        console.log(error);
    }
}



const handleTableInputChange = (index, event) => {
    const values = [...mealDetail];
    values[index][event.target.name] = event.target.value;
    setMealDetail(values);
    updateSummedQuantities(values, mealSummary);
};

const updateSummedQuantities = (data, summary) => {
    const totals = data.reduce((acc, item) => {
        const existingItem = acc.find(i => i.eng_name === item.eng_name);
        if (existingItem) {
            existingItem.total_planned_batch_raw_qty += parseFloat(item.planned_batch_raw_qty) || 0;
        } else {
            acc.push({
                eng_name: item.eng_name,
                total_planned_batch_raw_qty: parseFloat(item.planned_batch_raw_qty) || 0
            });
        }
        return acc;
    }, []);

    const updatedSecondArray = summary.map((item, index) => {
        const total_planned_batch_raw_qty = totals[index] ? totals[index].total_planned_batch_raw_qty : item.total_planned_batch_raw_qty;
        const total_planned_raw_qty = parseFloat(item.total_planned_raw_qty);
        const pending_planned_qty = (total_planned_batch_raw_qty - total_planned_raw_qty)
        return {
            ...item,
            total_planned_batch_raw_qty : total_planned_batch_raw_qty,
            pending_planned_qty: pending_planned_qty
        };
    });
    // console.log(updatedSecondArray);
    setMealSummary(updatedSecondArray)
};

const handleTableSelectChange = (index, selectedOption, name) => {
    const values = [...mealDetail];
    values[index][name.name] = selectedOption;
    setMealDetail(values);
};
const handleAddRow = (index) => {
    const currentBatch = mealDetail.filter((item) => item.menu_item_id == mealDetail[index].menu_item_id).length ?? 0;

    const currentMenuItemId = mealDetail[index].menu_item_id;

    // Find the index of the last occurrence of currentMenuItemId
    let lastIndex = mealDetail.length - 1;
    for (let i = lastIndex; i >= 0; i--) {
        if (mealDetail[i].menu_item_id === currentMenuItemId) {
            lastIndex = i;
            break;
        }
    }


    const newRow = {
        batch :currentBatch + 1,
        menu_item_id:  mealDetail[index].menu_item_id,
        item_name: mealDetail[index].item_name,
        eng_name:  mealDetail[index].eng_name,
        planned_batch_raw_qty: 0,
        vessel_id: "",
        start_time: "",
        end_time:"",
        remarks : ""
    };
    const updatedMealDetail = [...mealDetail];
    
    // Insert the new row at the next index after the current index
    updatedMealDetail.splice(lastIndex + 1, 0, newRow);
    
    // Update the state with the updated array
    setMealDetail(updatedMealDetail);
    // setMealDetail([...mealDetail, newRow]);
};

const handleRemoveRow = (index) => {
    const updatedMealDetail = mealDetail.filter((_, i) => i !== index);
    
    // Update the state with the new array
    setMealDetail(updatedMealDetail);
    setRemoveMealDetail([...removeMealDetail, mealDetail.filter((_, i) => i == index)[0]]);
}

const handelRemarks = (e) => {
    e.preventDefault();
    const updatedArray = mealDetail.map(obj => ({
      ...obj,
      remarks: e.target.value
    }));
    
    setMealDetail(updatedArray);
  }

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
        Production Planning
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
        
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        {mealList.length > 0 && <table className="border-collapse w-full mb-4">
            <thead>
                <tr  className="bg-gray-100">
                    <td className="border px-4 py-2 font-bold">Menu Item ID</td>
                    {mealList.map((item, index) => (
                        <td key={index} className="border px-4 py-2">{item.eng_name}</td>
                    ))}
                </tr>
                <tr >
                    <td className="border px-4 py-2 font-bold">Total Planned Raw Qty.</td>
                    {mealSummary.map((item, index) => (
                        <td key={index} className="border px-4 py-2">{item.total_planned_raw_qty}</td>
                    ))}
                </tr>
                <tr className="">
                    <td className="border px-4 py-2 font-bold">Total Planned Batch Raw Qty.</td>
                    {mealSummary.map((item, index) => (
                        <td key={index} className="border px-4 py-2">{item.total_planned_batch_raw_qty ?? 0}</td>
                    ))}
                </tr>
                <tr>
                    <td className="border px-4 py-2 font-bold">Pending Planned Qty.</td>
                    {mealSummary.map((item, index) => (
                        <td key={index} className={`${item.pending_planned_qty < 0 ? "bg-red-500" : (item.pending_planned_qty == 0 ? "" : "bg-blue-400")} "border px-4 py-2"`}>{item.pending_planned_qty ?? 0}</td>
                    ))}
                </tr>
            </thead>
        </table>}
        {mealList.length > 0 &&<table className="table-auto border-collapse w-full">
            <thead>
                <tr className="bg-gray-200">
                    <th className="border px-4 py-2">Menu Item</th>
                    <th className="border px-4 py-2">Batch</th>
                    <th className="border px-4 py-2">Planned Batch Raw Qty<span className="text-red-500">*</span></th>
                    <th className="border px-4 py-2">Vessel Name<span className="text-red-500">*</span></th>
                    <th className="border px-4 py-2">Start Time</th>
                    <th className="border px-4 py-2">End Time</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {mealDetail.length > 0 && mealDetail.map((item, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">{item?.eng_name ?? ''}</td>
                            <td className="border px-4 py-2">{item?.batch ?? ''}</td>
                            <td className="border px-4 py-2">
                                <input 
                                    type="number"
                                    step="0.01"
                                    id="planned_batch_raw_qty"
                                    placeholder="Planned Batch Raw Qty"
                                    name="planned_batch_raw_qty"
                                    value={item?.planned_batch_raw_qty || ''}
                                    onChange={(event) => handleTableInputChange(index, event)}
                                    onWheel={(e) => e.target.blur()}
                                    disabled={item.itemDisable}
                                    className={`p-1 border rounded-md placeholder:text-sm ${item.itemDisable ? "": "bg-orange-50"} w-full`} 
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <Select
                                    classNamePrefix="select"
                                    name="vessel_id"
                                    styles={{
                                        placeholder: (provided) => ({
                                            ...provided,
                                            fontSize: "0.80rem",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            backgroundColor: item.itemDisable ? "" : "#FFFAF0",
                                            fontSize: "16px",
                                        }),
                                    }}
                                    value={item?.vessel_id ?? ''}
                                    onChange={(selectedOption, name) => handleTableSelectChange(index, selectedOption, name)}
                                    placeholder="Vessel Name"
                                    options={vesselList.map((vessel) => ({
                                        value: vessel.id,
                                        label: vessel.name,
                                    }))}
                                    isDisabled={item.itemDisable}
                                    filterOption={(option, inputValue) => {
                                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <Select
                                    classNamePrefix="select"
                                    name="start_time"
                                    styles={{
                                        placeholder: (provided) => ({
                                            ...provided,
                                            fontSize: "0.80rem",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            backgroundColor: item.itemDisable ? "" : "#FFFAF0",
                                            fontSize: '16px',
                                        }),
                                    }}
                                    value={item?.start_time ?? ''}
                                    onChange={(selectedOption, name) => handleTableSelectChange(index, selectedOption, name)}
                                    placeholder="Start Time"
                                    isDisabled={item.itemDisable}
                                    className="placeholder:text-sm ms-2 w-full"
                                    options={timeList.map((timeSlot) => ({
                                        value: timeSlot.value,
                                        label: timeSlot.label,
                                    }))}
                                    filterOption={(option, inputValue) => {
                                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <Select
                                    classNamePrefix="select"
                                    name="end_time"
                                    styles={{
                                        placeholder: (provided) => ({
                                            ...provided,
                                            fontSize: "0.80rem",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            backgroundColor: item.itemDisable ? "" : "#FFFAF0",
                                            fontSize: '16px',
                                        }),
                                    }}
                                    value={item?.end_time ?? ''}
                                    onChange={(selectedOption, name) => handleTableSelectChange(index, selectedOption, name)}
                                    placeholder="End Time"
                                    className="placeholder:text-sm ms-2 w-full"
                                    options={timeList.map((timeSlot) => ({
                                        value: timeSlot.value,
                                        label: timeSlot.label,
                                    }))}
                                    isDisabled={item.itemDisable}
                                    filterOption={(option, inputValue) => {
                                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                />
                            </td>
                            <td className="border px-4 py-2">
                                 
                            {item?.batch == 1 && <button 
                                    onClick={() => handleAddRow(index)} 
                                    className="p-1 bg-blue-500 text-white rounded">
                                    Add Row
                                </button>}
                                {item?.batch > 1 && <button 
                                disabled={item.removeRow}
                                    onClick={() => handleRemoveRow(index)} 
                                    className="p-1 bg-red-500 text-white rounded">
                                    Remove
                                </button>}
                            </td>
                           
                        </tr>
                    ))}
                
            </tbody>
        </table>}
        <div className="w-full md:w-auto p-5">
    <input
      type="text"
     
      id="remarks"
      name="remarks"
      placeholder="Remarks"
      onChange={(e) => handelRemarks(e)}
      value={mealDetail?.[0]?.remarks ?? ""} 
      className="w-full md:w-auto px-2 py-1 border rounded-md bg-orange-50"
    />
  </div>
        {mealList.length > 0 &&<div>
    <button 
        onClick={(e) => handleSave(e)} 
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
    >
        Save
    </button>
</div>}

      </div>
    </div>
  );
}

export default ProductionPlanningList;
